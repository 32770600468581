import { MouseEventHandler, ReactElement, useRef, useState } from "react";
import ITag from "../../../app/models/ITag";
import useOnClickOutside from "../../../app/utils/hooks/useOnClickOutside";
import { styled } from "../../../app/utils/theme";
import GhostButton from "../../../components/buttons/GhostButton";

const DropDownItemsWrapper = styled.div`
  position: absolute;
  left: 0;
  background: white;
  border-top: solid 1px ${({theme}) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  z-index: 10;
`;

const Container = styled.div`
  position: relative;
`;

const DropDownButton = styled(GhostButton)`
  font-size: 1.5rem;
  text-align: left;
`;

const DropDownItem = styled(GhostButton)<{active: boolean}>`
  display: block;
  background: white;
  border: none;
  text-align: left;
  font-size: 1.5rem;
  cursor: pointer;
  background: ${p => p.active && p.theme.colors.box};
  &:hover {
    background: ${({theme}) => theme.colors.box};
  }
`;

interface IProps {
  tags: ITag[];
  selectedId: string | string[] | null;
  selectTag: (tagId: string) => void;
}

const TagDropdown = ({ tags, selectedId, selectTag}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const clickOutsideRef = useRef(null);
  const toggleRef = useRef<HTMLButtonElement | null>(null);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  useOnClickOutside(clickOutsideRef, (event) => {
    const toggleClick = toggleRef?.current == event.target;
    if(!toggleClick) {
      setIsOpen(false);
    }
  });

  const allLabel = "All";

  const onTagSelect = (tagid: string) => {
    selectTag(tagid); 
    setIsOpen(false);
  }


  return (
    <Container>
      <DropDownButton ref={toggleRef} onClick={toggle}>{tags.find(x => x.id === selectedId)?.name || allLabel}</DropDownButton>
      {isOpen && 
      <DropDownItemsWrapper ref={clickOutsideRef}>
        <DropDownItem active={!selectedId} onClick={() => onTagSelect('')}>{allLabel}</DropDownItem>
        {tags.map(tag => <DropDownItem active={tag.id === selectedId} key={tag.id} onClick={() => onTagSelect(tag.id)}>{tag.name}</DropDownItem>)}
      </DropDownItemsWrapper>
      }
    </Container>
  );
};

export default TagDropdown;
