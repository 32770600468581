import { styled } from "../../app/utils/theme";

const Button = styled.button`
  background: ${({theme}) => theme.colors.primary};
  border: none;
  padding: 1rem 2rem;
  width: fit-content;
  cursor: pointer;
`;

export default Button;