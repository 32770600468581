export enum size {
  SMALL = 375,
  MEDIUM =  768,
  LARGE = 1024,
  HUGE =  2000,
};


export const biggerThan = (size: size) => {
  return `@media (min-width: ${size}px)`
}

export const smallerThan = (size: size) => {
  return `@media (max-width: ${size}px)`
}
