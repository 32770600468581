import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useRef, useState } from "react";
import useOnClickOutside from "../../app/utils/hooks/useOnClickOutside";
import { styled } from "../../app/utils/theme";
import GhostButton from "../buttons/GhostButton";
import DropDownItem from "./DropDownItem";

const DropDownItemsWrapper = styled.div`
  position: absolute;
  right: 0;
  background: white;
  width: 200px;
  z-index: 15;
  border-top: solid 1px ${({theme}) => theme.colors.primary};
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const DropDownButton = styled(GhostButton)<{fontSize: string}>`
  font-size: ${p => p.fontSize};
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

interface IProps {
  items: ReactElement[];
  title?: string;
  icon?: IconDefinition;
  fontSize?: string;
}

const DropDown = ({ items, title, fontSize = '1rem', icon }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const clickOutsideRef = useRef(null);
  const toggleRef = useRef<HTMLButtonElement | null>(null);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  useOnClickOutside(clickOutsideRef, (event) => {
    const toggleClick = toggleRef?.current === event.target;
    if(!toggleClick) {
      setIsOpen(false);
    }
  });

  return (
    <Container>
      <DropDownButton fontSize={fontSize} ref={toggleRef} onClick={toggle}>{icon && <Icon icon={icon} />}{title}</DropDownButton>
      {isOpen && 
      <DropDownItemsWrapper ref={clickOutsideRef}>
        {items.map((item) => <DropDownItem key={item.key}>{item}</DropDownItem>)}
      </DropDownItemsWrapper>
      }
    </Container>
  );
};

export default DropDown;
