
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import IProduct from "../../app/models/IProduct";
import api from "../../app/utils/api";
import Layout from "../../components/layout/Layout";
import ProductsView from "../../features/productsView/ProductsView";

const CollectionPage = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { collectionId } = useParams();

  useEffect(() => {
    if(pageNumber !== 1) {
      setPageNumber(1);
    }
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.append("collectionId", collectionId!.toString());
    
    api.products.list(queryParams).then(response => {
      
      setProducts(response.items);
      setHasNextPage(response.hasNextPage);
      setIsLoading(false);
    })
  }, [collectionId])


  const loadNextPage = () => {
    setIsLoading(true);
    const page = pageNumber + 1;
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.append("pageNumber", page.toString());
    queryParams.append("collectionId", collectionId!.toString());
    
    api.products.list(queryParams).then(response => {
      setProducts(response.items);
      setHasNextPage(response.hasNextPage);
      setPageNumber(page);
      setIsLoading(false);
    })
  }

  
  return (
    <div>
      <Layout>
        <h1>{collectionId}</h1>
        <ProductsView products={products} hasNextPage={hasNextPage} loadNextPage={loadNextPage} isLoading={isLoading} isFilterOpen={isFilterOpen} />
      </Layout>
    </div>
  )
}

export default CollectionPage;


