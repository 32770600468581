import { FormEvent, useState } from "react";
import IProductForm from "../../app/models/requests/IProductForm";
import api from "../../app/utils/api";
import { styled } from "../../app/utils/theme";
import Button from "../../components/buttons/Button";
import TextInput from "../../components/Input/TextInput";
import Layout from "../../components/layout/Layout";
import AdminImageUpload from "./adminImageUpload/AdminImageUpload";
import IUploadResult from "./adminImageUpload/IUploadResult";
import AdminTagPicker from "./adminTagPicker/AdminTagPicker";

const AdminContainer = styled.section``;

const initialProductForm: IProductForm = {
  imageUrlPrimary: "",
  imageUrlSecondary: "",
  link: "",
  price: 0,
  tagIds: [],
  title: "",
};

const AdminPage = () => {
  const [form, setForm] = useState<IProductForm>(initialProductForm);

  const createProduct = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    var response = await api.products.create(form);
    setForm(initialProductForm);
  };

  return (
    <div>
      <Layout>
        <AdminContainer>
          <form onSubmit={createProduct}>
            <h2>Create a new product</h2>
            <div>
              <label>Title</label>
              <TextInput
                value={form.title}
                onChange={(e) => setForm({...form, title: e.target.value})}
              />
            </div>
            <div>
              <label>Price</label>
              <TextInput
                value={form.price}
                onChange={(e) => setForm({...form, price: parseFloat(e.target.value)})}
                type="number"
              />
            </div>
            <div>
              <label>Link to product</label>
              <TextInput
                value={form.link}
                onChange={(e) => setForm({...form, link: e.target.value})}
              />
            </div>
            <div>
              <label>Tags</label>
              <AdminTagPicker
                selectedTagIds={form.tagIds}
                setTags={(tagIds: string[]) => setForm({...form, tagIds: tagIds})}
              />
            </div>
            <div>
              <label>Primary image</label>
              <AdminImageUpload
                setUploadResult={(value: IUploadResult) =>
                  setForm({...form, imageUrlPrimary: value.id})
                }
              />
              <TextInput value={form.imageUrlPrimary} disabled />
            </div>
            <div>
              <label>Secondary image</label>
              <AdminImageUpload
                setUploadResult={(value: IUploadResult) =>
                  setForm({...form, imageUrlSecondary: value.id})
                }
              />
              <TextInput value={form.imageUrlSecondary} disabled />
            </div>
            <Button type="submit">Create</Button>
          </form>
        </AdminContainer>

      </Layout>
    </div>
  );
};

export default AdminPage;
