import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import { lightTheme, ThemeProvider } from "../utils/theme";
import Main from "../../pages/Main";
import LoginPage from "../../pages/account/Login";
import ProuctsPage from "../../pages/products/ProductPage";
import AdminPage from "../../pages/admin/AdminPage";
import CollectionPage from "../../pages/collections/CollectionPage";
import UserPage from "../../pages/users/UserPage";

const App = () => {
  return (
    <BrowserRouter>
    <ThemeProvider theme={lightTheme}>
      <Routes>
  
        <Route path="/account/login" element={<LoginPage />} />
        <Route path="admin/tags" />
        <Route path="/admin" element={<AdminPage />}/>
        <Route path="/products/:productId" element={<ProuctsPage />} />
        <Route path="/collections/:collectionId" element={<CollectionPage />} />
        <Route path="/users/:userId" element={<UserPage />} />
        <Route path="/" element={<Main />} />
      </Routes>
    </ThemeProvider>
    
  </BrowserRouter>
  );
}

export default App;
