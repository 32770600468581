import React from "react";
import { styled } from "../../app/utils/theme";
import Header from "../../features/header/Header";
import Modal from "../modal/Modal";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
`;

interface IProps {
  children: React.ReactNode;
}

const Layout: React.FC<IProps> = ({ children }) => {
  return (<>
    <Container>
    <Header />
      <div>
        {children}
      </div>
    </Container>
    <Modal />
    </>
  );
};

export default Layout;
