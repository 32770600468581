import { PixelCrop } from "react-image-crop";

export function getCroppedImg(imageRef: HTMLImageElement, crop: PixelCrop) : Promise<File> {
  let image = imageRef;
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = Math.ceil(crop.width*scaleX);
  canvas.height = Math.ceil(crop.height*scaleY);
  const ctx = canvas.getContext('2d');
  ctx!.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width*scaleX,
    crop.height*scaleY,
  );
  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');
  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob: any) => {
      blob.name = 'filename.jpg';
      resolve(blob);
    }, 'image/jpeg',1);
  });
}