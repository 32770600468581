import { styled } from "../../app/utils/theme";

const Container = styled.div`
  background: #e74c3c;
  border-left: #c0392b solid 0.5rem;
  color: white;
  padding: 1rem;
`;

interface IProps {
  message: string;
  type?: alertType;
}

type alertType = 'ERROR' | 'WARNING'

const styles = {
  ERROR: {
    background: 'orangered',
    highlight: 'red'
  },
  WARNING: {
    background: 'yellowgreen',
    highlight: 'yellow'
  },
}

const AlertBox = ({message, type = 'ERROR'} : IProps) => {
  if(!message) return null;

  return(
    <Container>
      {message}
    </Container>
  )
}

export default AlertBox;