import { keyframes } from "styled-components";
import { styled } from "../../app/utils/theme";


const shimmer = keyframes`
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
`;

const Placeholder = styled.div<{height?: number}>`
 ${p => p.height ? `height: ${p.height}`: 'aspect-ratio: 1'};
  height: ${p => p.height}px;
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
  margin-bottom: 1rem;
`;

const ProductSkeleton = () => {
  return(
    <div>
      <Placeholder />
      <Placeholder height={20} />
      <Placeholder height={20} />
    </div>
  )
}

export default ProductSkeleton;