import { JSXElementConstructor, Key, ReactElement } from "react";
import { styled } from "../../app/utils/theme";

const Container = styled.div`
  &:hover {
    background: ${({theme}) => theme.colors.box};
  }
`;

interface IProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>; key: Key | null;
}

const DropDownItem = ({children}: IProps) => {
  return(<Container>{children}</Container>)
}

export default DropDownItem;  