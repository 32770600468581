import IProduct from "../../app/models/IProduct";
import { styled } from "../../app/utils/theme";
import Button from "../../components/buttons/Button";
import ProductSkeleton from "../../components/Skeletons/ProductSkeleton";
import FilterColumn from "./FilterColumn";
import Product from "./Product";

const Container = styled.div`
  display: flex;
`;
const ProductsColumn = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 1.5rem;
  row-gap: 2rem;
  padding: 1rem;
`;

const LoadMoreButton = styled(Button)`
  margin: 5rem auto;
  display: block;
  font-size: large;
  cursor: pointer;
`;

const NoResults = styled.p`
  text-align: center;
`;


interface IProps {
  products: IProduct[];
  isLoading: boolean;
  loadNextPage: () => void;
  hasNextPage: boolean;
  isFilterOpen: boolean;
}

const ProductsView = ({ products, isLoading, loadNextPage, hasNextPage, isFilterOpen}: IProps) => {
  return(
    <section>
      <Container>
        <ProductsColumn>
          {products.map(product => {
            return <Product key={product.id} product={product} />
          })}
          {isLoading && [...Array(20)].map((x, i) => <ProductSkeleton key={i} />)}
        </ProductsColumn>
        {isFilterOpen && <FilterColumn />}
      </Container>
      {hasNextPage && !isLoading && <LoadMoreButton onClick={loadNextPage}>Show more</LoadMoreButton>}
      {!isLoading && products.length === 0 && <NoResults>No results found :(</NoResults>}
    </section>
  )
}

export default ProductsView;