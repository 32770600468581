import { ChangeEvent, useEffect, useState } from "react";
import ITag from "../../../app/models/ITag";
import api from "../../../app/utils/api";

interface IProps {
  selectedTagIds: string[];
  setTags: (tags: string[]) => void
}

const AdminTagPicker = ({ selectedTagIds, setTags }: IProps) => {
  const [possibleTags, setPossibleTags] = useState<ITag[]>();

  useEffect(() => {
    api.tags.list().then((response) => {
      setPossibleTags(response);
    });
  }, []);


  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    if(selectedTagIds.includes(event.target.id)) {
      setTags(selectedTagIds.filter(tag => tag != event.target.id))
    } else {
      setTags([...selectedTagIds, event.target.id])
    }
  }

  return (
    <div>
      {possibleTags?.map((tag) => (
        <div key={tag.id}>
          <label htmlFor={tag.id}>{tag.name}</label>
          <input id={tag.id} type="checkbox" onChange={handleToggle} checked={selectedTagIds.includes(tag.id)} />
        </div>
      ))}
    </div>
  );
};

export default AdminTagPicker;
