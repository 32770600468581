import * as styledComponents from "styled-components";

export const shouldUseLightTheme = () => {
  const systemPrefersLight = !window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const storedUsingLight = localStorage.getItem("usingLightTheme");
  return true;
  return storedUsingLight !== null
      ? storedUsingLight !== "false"
      : systemPrefersLight;

}


export const lightTheme = {
  colors: {
    primary: "#D3FFE7",
    primaryDarker: "#a5c9b6",
    box: '#ecf0f1',
    text: '#1e272e',
    textSecondary: '#526370',
    base: "white",
  },
};

export const darkTheme = {
  ...lightTheme,
  colors: {
    primary: "#D3FFE7",
    primaryDarker: "#a5c9b6",
    box: '#808e9b',
    text: '#d2dae2',
    textSecondary: '#a8afb5',
    base: "#1e272e",
  },
};

export type Theme = typeof lightTheme;

export const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;