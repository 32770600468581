import { useRef } from "react";
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome'
import { faUmbrella } from '@fortawesome/free-solid-svg-icons'
import { styled } from "../../app/utils/theme";
import useOnClickOutside from "../../app/utils/hooks/useOnClickOutside";
import { useStore } from "../../app/stores/AppState";


const Background = styled.section`
  background: rgba(224, 224, 224, 0.85);
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 99;
`;

const Container = styled.div`
  background: white;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 10rem;
  min-width: 30rem;
`;

const TopBar = styled.div`

  h3 {
    margin: 0;
    text-align: center;
  }
`;



const TopIcon = styled(FontAwesomeIcon)`
  color: ${p => p.theme.colors.primaryDarker};
  display: block;
  margin: 0 auto;
  padding: 1rem;
  font-size: 1.5rem;
`;

const Modal = () => {
  const modal = useStore(state => state.modal);
  const setModal = useStore(state => state.setModal)

  const containerRef = useRef(null);

  useOnClickOutside(containerRef, () => setModal(undefined));

  if (!modal) {
    return null;
  }

  return (
    <Background>
      <Container ref={containerRef}>
        <TopBar>
          <TopIcon icon={modal.icon ? modal.icon  : faUmbrella} />
          <h3>{modal.title}</h3>
        </TopBar>
        {modal.body}
      </Container>
    </Background>
  );
};

export default Modal;
