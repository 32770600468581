import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import IProduct from "../../app/models/IProduct";
import api from "../../app/utils/api";
import { size, smallerThan } from "../../app/utils/devices";
import { styled } from "../../app/utils/theme";
import Layout from "../../components/layout/Layout";
import ProductSkeleton from "../../components/Skeletons/ProductSkeleton";
import Price from "../../features/productsView/Price";
import Tag from "../../features/productsView/Tag";

const ProductSection = styled.section`
  
`;

const RelatedSection = styled.section`

`;

const TagsWrapper = styled.div`
  padding: 0.5rem 0;
  display: flex;
`;

const Images = styled.div`
  display: flex;
  justify-content: space-between;
  ${smallerThan(size.MEDIUM)} {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: calc(50% - 0.5rem);
  aspect-ratio: 1 / 1;
  object-fit: cover;
  ${smallerThan(size.MEDIUM)} {
    width: 100%;
  }
`;

const Content = styled.div`
  ${smallerThan(size.HUGE)} {
    padding: 1rem;
  }
`;

const NavigateToSite = styled.a`
  padding: 1rem;
  display: inline-block;
  margin: 1rem 0;
  background: ${p => p.theme.colors.primaryDarker};
`;

const LinkIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;


const ProuctsPage = () => {

  const { productId } = useParams();

  const  [product, setProduct] = useState<IProduct | null>(null);


  useEffect(() => {
    if(!productId) {
      return;
    }
    api.products.details(productId.toString()).then(response => {
      setProduct(response);
    })
  }, [productId])

  const CDN_BASE = process.env.REACT_APP_CDN;

  
  return (
    <div>
      <Layout>
        <ProductSection>
          <Images>
            <Image alt={product?.title} src={`${CDN_BASE}/${product?.imageUrlPrimary}`} />
            <Image alt={product?.title} src={`${CDN_BASE}/${product?.imageUrlSecondary}`} />   
          </Images>
          <Content>
            <h1>{product?.title}</h1>
            <TagsWrapper>
              <Price>${product?.price}</Price>
              {product?.tags.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </TagsWrapper>
            <NavigateToSite target="_blank" href={product?.link}><LinkIcon icon={faLink} />Navigate to site</NavigateToSite>
          </Content>
        </ProductSection>
        <RelatedSection>
          <Content>
            <h2>Related products</h2>
            {[...Array(10)].map((x, i) => <ProductSkeleton key={i} />)}
          </Content>
        </RelatedSection>
        {}
        
      </Layout>
    </div>
  )
}

export default ProuctsPage;


