import { ChangeEvent, useRef, useState } from "react";
import TextInput from "../../../components/Input/TextInput";
import IUploadResult from "./IUploadResult";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { getCroppedImg } from "./cropUtils";
import api from "../../../app/utils/api";
import Button from "../../../components/buttons/Button";

interface IProps {
  setUploadResult: (value: IUploadResult) => void;
}

const AdminImageUpload = ({ setUploadResult: setResult }: IProps) => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [src, setSrc] = useState("");
  const imageRef = useRef<HTMLImageElement>(null);

  const handleUpload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (completedCrop) {
      const croppedImage = await getCroppedImg(imageRef.current!, completedCrop);
      api.uploads.upload(croppedImage).then((response) => {
        setResult(response);
      });
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setSrc(reader.result!.toString() || "")
      );
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <>
      <TextInput onChange={handleFileChange} type="file" />
      {src && (
        <>
          <ReactCrop
            crop={crop}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={1}
            onChange={(c) => setCrop(c)}
          >
            <img src={src} ref={imageRef} />
          </ReactCrop>
          <Button onClick={handleUpload}>Upload</Button>
        </>
      )}
    </>
  );
};

export default AdminImageUpload;
