
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import IProduct from '../app/models/IProduct';
import api from '../app/utils/api';
import Layout from '../components/layout/Layout';
import ProductsView from '../features/productsView/ProductsView';
import SortFilter from '../features/productsView/SortFilter/SortFilter';

const Main = () => {

  const [products, setProducts] = useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if(pageNumber !== 1) {
      setPageNumber(1);
    }
    const queryParams = new URLSearchParams(window.location.search);
    api.products.list(queryParams).then(response => {
      setProducts(response.items);
      setHasNextPage(response.hasNextPage);
      setIsLoading(false);
    })
  }, [searchParams])


  const loadNextPage = () => {
    setIsLoading(true);
    const page = pageNumber + 1;
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.append("pageNumber", page.toString());
    
    api.products.list(queryParams).then(response => {
      setProducts(p => [...p, ...response.items]);
      setHasNextPage(response.hasNextPage);
      setPageNumber(page);
      setIsLoading(false);
    })
  }

  return (
    <Layout>
      <SortFilter toggleFilterOpen={() => setIsFilterOpen(!isFilterOpen)}/>
      <ProductsView products={products} hasNextPage={hasNextPage} loadNextPage={loadNextPage} isLoading={isLoading} isFilterOpen={isFilterOpen} />
    </Layout>
  )
}

export default Main
