import { FormEvent, useState } from "react";
import { useStore } from "../../../app/stores/AppState";
import api from "../../../app/utils/api";
import { styled } from "../../../app/utils/theme";
import Button from "../../../components/buttons/Button";
import TextInput from "../../../components/Input/TextInput";

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NewCollectionForm = () => {
  const [collectionName, setCollectionName] = useState("");

  const setCollections = useStore(state => state.setCollections);
  const collections = useStore(state => state.collections);
  const setModal = useStore(state => state.setModal);

  const createNewCollection = async (e: FormEvent) => {
    e.preventDefault();
    var response = await api.collections.create(collectionName);
    setModal(undefined);
    setCollections([...collections, { id: response.id, name: collectionName, numberOfProducts: 0 }])
  };

  return (
    <Form onSubmit={createNewCollection}>
      <TextInput
        value={collectionName}
        onChange={(e) => setCollectionName(e.target.value)}
        placeholder="Title"
        type="text"
        autoFocus
      />
      <Actions>
        <SubmitButton type="submit">Create</SubmitButton>
      </Actions>
    </Form>
  );
};

export default NewCollectionForm;
