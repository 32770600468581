import { styled } from "../../app/utils/theme";


const TextInput = styled.input`
  background: ${({ theme }) => theme.colors.box};
  border: none;
  display: block;
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem;
`;

export default TextInput;