import { formatDistance } from "date-fns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlus, faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import { styled } from "../../app/utils/theme";
import NewCollectionForm from "./forms/NewCollectionForm";
import DropDown from "../../components/dropdown/Dropdown";
import IProduct from "../../app/models/IProduct";
import api from "../../app/utils/api";
import { useStore } from "../../app/stores/AppState";
import { useState } from "react";
import { Link } from "react-router-dom";
import Price from "./Price";
import Tag from "./Tag";
import GhostButton from "../../components/buttons/GhostButton";

const Container = styled.div`

`;

const Images = styled.div`

`;

const Image = styled.img`
  width: 100%;
  position: absolute;
  &:hover {
    opacity: 0;
  }

`;

const ImageSecondary = styled(Image)`
  position: absolute;
`;

const ThumbnailContainer = styled.div`
  width: 100%;
  background: ${p => p.theme.colors.box};
  aspect-ratio: 1 / 1;
  position: relative;
`;

const MetadataWrapper = styled.div`
  padding: 0.5rem 0;
  display: flex;
`;

const Title = styled.h3`
  margin: 0;
`;

const Description = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const Author = styled.span`
  font-weight: bold;
`;

const SideBar = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
`;

const Saves = styled.div`
  background: white;
  padding: 0.5rem 0 0.5rem 0.5rem;
  display: flex;
  align-items: baseline;
`;

const CollectionButton = styled(GhostButton)`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
`;

const CollectionTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IProps {
  product: IProduct;
}

const Product = ({ product }: IProps) => {
  const [inCollections, setInCollections] = useState<string[]>(product.inCollections);
  const collections = useStore(state => state.collections);
  const setCollections = useStore(state => state.setCollections);
  const setModal = useStore(state => state.setModal);

  const onAddToCollection = async (collectionId: string) => {
    await api.collections.addProduct(collectionId, product.id);
    setInCollections([...inCollections, collectionId])
    const collection = collections.find(x => x.id === collectionId);
    if(collection == null) return;
    collection.numberOfProducts = collection?.numberOfProducts || 0 + 1;
    setCollections([...collections.filter(x => x.id !== collectionId), collection!]);
  };

  const onRemoveFromCollection = async (collectionId: string) => {
    await api.collections.removeProduct(collectionId, product.id);
    setInCollections([...inCollections.filter(x => x !== collectionId)])
    const collection = collections.find(x => x.id === collectionId);
    if(collection == null) return;
    collection.numberOfProducts = collection?.numberOfProducts || 0 - 1;
    setCollections([...collections.filter(x => x.id !== collectionId), collection!]);
  };

  const CDN_BASE = process.env.REACT_APP_CDN;


  return (
    <Container>
      <ThumbnailContainer>
        <SideBar>
            <Saves>
              <span>{product.numberOfSaves}</span>
              <DropDown
                icon={inCollections.length > 0 ? faCheck : faPlus}
                items={[
                  ...collections.map((collection) => {
                    const isInCollection = inCollections.includes(collection.id);
                    return ((
                      <CollectionButton
                        onClick={() => isInCollection ? onRemoveFromCollection(collection.id) : onAddToCollection(collection.id)}
                        key={collection.id}
                      >
                        <CollectionTitle>{collection.name}</CollectionTitle><FontAwesomeIcon icon={isInCollection ? faCheck : faPlus} />
                      </CollectionButton>
                    ))
                  }),
                  <CollectionButton
                    onClick={() => setModal({
                    title: 'New list',
                    icon: faFolderPlus,
                    body: <NewCollectionForm />
                  })} key="new">Create new list <FontAwesomeIcon icon={faFolderPlus} /></CollectionButton>,
                ]}
              ></DropDown>
            </Saves>
          </SideBar>
          <Link to={`products/${product.id}`}>
            <Images>
              <ImageSecondary loading="lazy"  src={`${CDN_BASE}/${product.imageUrlSecondary}`}  alt={product.title} />
              <Image src={`${CDN_BASE}/${product.imageUrlPrimary}`} alt={product.title} />
            </Images>
          </Link>
      </ThumbnailContainer>

      <MetadataWrapper>
        <Price>${product.price}</Price>
        {product.tags.map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </MetadataWrapper>
      <Link to={`/products/${product.id}`}>
        <Title>{product.title}</Title>
      </Link>
      <Description>
        Found {formatDistance(new Date(product.createdAt), new Date())} ago by{" "}
        <Link to={`users/${product.creatorUsername}`}><Author>{product.creatorUsername}</Author></Link>
      </Description>
    </Container>
  );
};

export default Product;
