import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import create from 'zustand'
import { ICollection } from '../models/ICollection';
import { IUser } from '../models/IUser';
import { persist } from 'zustand/middleware'

interface Modal {
  title: string;
  body: React.ReactNode;
  icon?: IconDefinition;
  closable?: boolean;
}
interface AppState {
  user?: IUser;
  setUser: (user: IUser) => void;
  signOut: () => void;
  collections: ICollection[];
  setCollections: (coll: ICollection[]) => void;
  modal?: Modal;
  setModal: (modal: Modal | undefined) => void;
}

export const useStore = create(
  persist<AppState>(
    (set) => ({
      user: undefined,
      setUser: (user: IUser) => {
        set({user: user});
      },
      signOut: () => {
        set({user: undefined});
        localStorage.removeItem("token");
      },
      collections: [],
      setCollections: (coll: ICollection[]) => set({collections: coll}),
      modal: undefined,
      setModal: (modal: Modal | undefined) => set({modal})
    }),
    {
      name: 'app-storage'
    }
  )
);