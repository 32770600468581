import { useEffect, useState } from "react";

import TagDropdown from "./TagDropdown";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { styled } from "../../../app/utils/theme";
import ITag from "../../../app/models/ITag";
import api from "../../../app/utils/api";
import { useSearchParams } from "react-router-dom";

const TabTitle = styled.h2<{active: boolean}>`
  margin: 0;
  color: ${({active, theme}) => active ? theme.colors.text : theme.colors.textSecondary};
  margin-right: 0.5rem;
  cursor: pointer;
  border-bottom: solid 2px ${({active, theme}) => active ? theme.colors.text : theme.colors.base};
`;

const Container = styled.section`
  margin: 0 1rem;
`;

const FilterToggle = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterHeaderLeft = styled.div`
  display: flex;
`;

const FilterHeaderRight = styled.div`
  display: flex;
`;

const FilterDropdown = styled.div`
  position: relative;
`;

const FilterIcon = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
`;

interface IProps {
  toggleFilterOpen: () => void
}


const SortFilter = ({toggleFilterOpen} : IProps) => {
  const [tags, setTags] = useState<ITag[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    api.tags.list().then(tagsResponse => {
      setTags(tagsResponse);
    })
  }, [])


  const updateParam = (field: string, value: string) => {
    const params = searchParams;
    if(value) {
      params.set(field, value);
      setSearchParams(searchParams)
    } else {
      params.delete(field);
    }
    setSearchParams(params);
  }

  return(
    <Container>
          <FilterHeader>
            <FilterHeaderLeft>
              <TabTitle onClick={() => updateParam('order', '')} active={!searchParams.get('order')}>New</TabTitle>
              <TabTitle onClick={() => updateParam('order', 'trending')} active={searchParams.get('order') === 'trending'}>Trending</TabTitle>
              
              <TagDropdown selectedId={searchParams.get('tagId')} selectTag={(tagId: string) => updateParam('tagId', tagId)} tags={tags} />
            </FilterHeaderLeft>
            <FilterHeaderRight>
              <FilterDropdown>
                <FilterToggle onClick={toggleFilterOpen}>Filters<FilterIcon icon={faEllipsisVertical} /></FilterToggle>
              </FilterDropdown>
            </FilterHeaderRight>
            
          </FilterHeader>
          
    </Container>
  )
}

export default SortFilter;