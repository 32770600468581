import { FC, useEffect } from "react";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { styled } from "../../app/utils/theme";
import { biggerThan, size, smallerThan } from "../../app/utils/devices";
import DropDown from "../../components/dropdown/Dropdown";
import { Link } from "react-router-dom";
import GhostButton from "../../components/buttons/GhostButton";
import GhostLinkButton from "../../components/buttons/GhostLinkButton";
import api from "../../app/utils/api";
import { useStore } from "../../app/stores/AppState";

const List = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  padding: 0;
  display: inline-block;
  display: flex;
  margin: 0;

  ${smallerThan(size.MEDIUM)} {
    flex-direction: column;

    ${(p) => !p.isOpen && "display: none"}
  }
`;

const ListItem = styled.li<{ isActive?: boolean }>`
  display: block;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  box-sizing: border-box;
  border-bottom: ${({ isActive, theme }) =>
    isActive ? `2px solid ${theme.colors.primary}` : "none"};
  &:hover {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
  ${biggerThan(size.MEDIUM)} {
    margin-left: 1rem;
  }
  ${smallerThan(size.MEDIUM)} {
    margin: 0 auto;
    padding: 1rem;
  }
`;

const CollectionItem = styled.a`
    display: block;
    padding: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IProps {
  isOpen: boolean;
}

const Navigation: FC<IProps> = ({ isOpen }) => {
  const signOut = useStore(state => state.signOut);
  const user = useStore(state => state.user);

  const collections = useStore(state => state.collections);
  const setCollections = useStore(state => state.setCollections);

  useEffect(() => {
    if (user) {
      api.users.listCollections(user.id!).then((response) => {
        setCollections(response);
      });
    }
  }, [setCollections, user, user?.id]);


  return (
    <>
      <List isOpen={isOpen}>
        {user ? (
          <>
            <ListItem>
              <DropDown
                key="2"
                title="Lists"
                icon={faHeart}
                items={collections.map((collection) => (
                  <Link to={`/collections/${collection.id}`} key={collection.id}>
                    <CollectionItem>{`${collection.name}`}</CollectionItem>
                  </Link>
                ))}
              ></DropDown>
            </ListItem>
            <ListItem>
              <GhostButton onClick={signOut} key="3">
                Log out
              </GhostButton>
            </ListItem>
          </>
        ) : (
          <ListItem>
            <GhostLinkButton to="/account/login">Sign in</GhostLinkButton>
          </ListItem>
        )}
      </List>
    </>
  );
};

export default Navigation;
