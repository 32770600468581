import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClose, faSearch } from '@fortawesome/free-solid-svg-icons'
import { biggerThan, size, smallerThan } from "../../app/utils/devices";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import Navigation from "./Navigation";
import { useState } from "react";


const Container = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  ${smallerThan(size.MEDIUM)} {
    flex-direction: column;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  align-items: center;
  padding: 1rem 0;
`;

const ToggleOpen = styled.button`
  background: none;
  border: none;
  outline: none;
  font-size: 1.5rem;
  cursor: pointer;

  ${biggerThan(size.MEDIUM)} {
    display: none;
  }
`;

const SearchInput = styled.input`
  border: none;
  background: none;
  outline: none;
  height: 1rem;
`;

const SearchWrapper = styled.div`
  ${biggerThan(size.MEDIUM)} {
    margin-left: 1rem;
  }
  background: #faf3f3;
  border-radius: 1rem;
  padding: 0 1rem;
  border: solid 1px transparent;
  display: flex;
  align-items: center;
  &:focus-within {
    border-color: black;
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((o) => !o);

  const [searchParams, setSearchParams] = useSearchParams();

  const updateParam = (value: string) => {
    const params = searchParams;
    if(value) {
      params.set('search', value);
    } else {
      params.delete('search');
    }
    setSearchParams(params);
    
    
  };

  return (
    <Container>
      <LogoWrapper>
        <Link to="/">Peplar</Link>
        <SearchWrapper>
          <SearchInput
            id="search"
            value={searchParams.get('search') || ''}
            onChange={(e) => updateParam(e.target.value)}
          />
          <label htmlFor="search"><FontAwesomeIcon icon={faSearch} /></label>
        </SearchWrapper>
        <ToggleOpen onClick={toggleOpen}><FontAwesomeIcon icon={isOpen ? faClose : faBars} /></ToggleOpen>
      </LogoWrapper>
      <Navigation isOpen={isOpen} />
    </Container>
  );
};

export default Header;
