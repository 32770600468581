import { FormEvent, useState } from "react";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import AlertBox from "../../components/alertBox/AltertBox";
import Layout from "../../components/layout/Layout";
import Button from "../../components/buttons/Button";
import api from "../../app/utils/api";
import IApiError from "../../app/models/IApiError";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/AppState";
import { styled } from "../../app/utils/theme";
import ILoginForm from "../../app/models/account/ILoginForm";
import TextInput from "../../components/Input/TextInput";

const Container = styled.section`
  margin: 0 auto;
  width: 300px;
`;

const LoginPage = () => {
  const setUser = useStore(state => state.setUser);
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState<ILoginForm>({
    email: '',
    password: ''
  });

  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setErrorMessage("");
      const response = await api.account.login(loginForm);
      setUser(response);
      navigate("/");
    } catch (error) {
      setErrorMessage((error as IApiError).errorCode || "Failed to login");
    }
  };

  const responseGoogle = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    try {
      const apiResponse = await api.account.googleLogin((response as GoogleLoginResponse).tokenId);
      setUser(apiResponse);
      navigate("/");
    } catch (error) {
      setErrorMessage((error as IApiError).errorCode || "Failed to login");
    }
    
  }
  const responseFailureGoogle = (response: GoogleLoginResponseOffline) => {
    console.log(response);
  }

  return (
    <Layout>
      <Container>
        <AlertBox message={errorMessage} />
        <h2>Sign in using..</h2>
        <GoogleLogin
          clientId="742831393020-efeith5udq4rop64s5997f2qe5fkmhgk.apps.googleusercontent.com"
          onSuccess={responseGoogle}
          onFailure={responseFailureGoogle}
          uxMode="popup"
        />

        <h2>Or</h2>
        <form onSubmit={handleLogin}>
          <TextInput
            onChange={(e) => setLoginForm({...loginForm, email: e.target.value})}
            value={loginForm.email}
            type="email"
            placeholder="Email"
          />
          <TextInput
            type="password"
            onChange={(e) => setLoginForm({...loginForm, password: e.target.value})}
            value={loginForm.password}
            placeholder="Password"
          />
          <Button type="submit">Sign in</Button>
        </form>
        <p>or create an account</p>
      </Container>
    </Layout>
  );
};

export default LoginPage;
