import { useSearchParams } from "react-router-dom";
import { size, smallerThan } from "../../app/utils/devices";
import { styled } from "../../app/utils/theme";
import TextInput from "../../components/Input/TextInput";


const Container = styled.div`
  width: 30rem;

  ${smallerThan(size.MEDIUM)} {
    padding: 1rem;
    position: absolute;
    width: 100%;
    background: white;
  }
`;

const FilterColumn = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateParam = (field: string, value: string) => {
    if(value) {
      searchParams.set(field, value);
    } else {
      searchParams.delete(field);
    }
    setSearchParams(searchParams);
  }

  const getParam = (field: string) => {
    return searchParams.get(field) || '';
  }

  return(
    <Container>
      <h3>Filters</h3>
      <section>
        <h4>Price</h4>
        <TextInput type="number" value={getParam('priceMin')} onChange={(e) => updateParam('priceMin', e.target.value)} />
            -
        <TextInput type="number" value={getParam('priceMax')} onChange={(e) => updateParam('priceMax', e.target.value)} />
    </section>
    </Container>
  )
}

export default FilterColumn;