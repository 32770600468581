import { useParams } from "react-router-dom";
import { size, smallerThan } from "../../app/utils/devices";
import { styled } from "../../app/utils/theme";
import Layout from "../../components/layout/Layout";
import ProductSkeleton from "../../components/Skeletons/ProductSkeleton";

const Content = styled.div`
  ${smallerThan(size.HUGE)} {
    padding: 1rem;
  }
`;

const Products = styled.section`
`;

const UserInfo = styled.section`
  padding: 1rem;
`;

const UserPage = () => {

  const { userId } = useParams();

  
  return (
    <div>
      <Layout>
        <UserInfo>
          <h1>{userId}</h1>
        </UserInfo>
        <Products>
          <Content>
            <h2>Products found</h2>
            {[...Array(10)].map((x, i) => <ProductSkeleton key={i} />)}
          </Content>
        </Products>
        {}
        
      </Layout>
    </div>
  )
}

export default UserPage;


