import axios, { AxiosResponse } from 'axios';
import ILoginForm from '../models/account/ILoginForm';
import IRegisterForm from '../models/account/IRegisterForm';
import { ICollection } from '../models/ICollection';
import IPage from '../models/IPage';
import IProduct from '../models/IProduct';
import ITag from '../models/ITag';
import IUploadResult from '../models/IUploadResult';
import { IUser } from '../models/IUser';
import IProductForm from '../models/requests/IProductForm';
import ITagForm from '../models/requests/ITagForm';
import { useStore } from '../stores/AppState';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(config => {
  const token = useStore.getState().user?.token;
  if (token) config.headers!.Authorization = `Bearer ${token}` 
  return config;
})


const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T> (url: string, queryParams: URLSearchParams | null = null) => axios.get<T>(url, {
    params: queryParams
  }).then(responseBody),
  post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  delete:<T> (url: string) => axios.delete<T>(url).then(responseBody),
}


const products = {
  list: (queryParams: URLSearchParams) => requests.get<IPage<IProduct>>('/products', queryParams),
  details: (productId: string) => requests.get<IProduct>(`/products/${productId}`),
  create: (form: IProductForm) => requests.post('/products', form)
}

const collections = {
  addProduct: (collectionId: string, productId: string) => requests.post(`/collections/${collectionId}/products`, {
    productId
  }),
  removeProduct: (collectionId: string, productId: string) => requests.delete(`/collections/${collectionId}/products/${productId}`),
  create: (name: string) => requests.post<{id: string}>('/collections', {
    name
  })
}


const tags = {
  list: () => requests.get<ITag[]>('/tags'),
  create: (tag: ITagForm) => requests.post('/tags', tag)
}


const account = {
  login: (loginFormData : ILoginForm) => requests.post<IUser>('/account/login', loginFormData),
  googleLogin: (token: string) => requests.post<IUser>('/account/external', { idToken: token, provider: 'google' }),
  register: (registerFormData: IRegisterForm) => requests.post<IUser>('/account/register', registerFormData),
  current: () => requests.get<IUser>('/account/current')
}

const users = {
  listCollections: (userId: string) => requests.get<ICollection[]>(`/users/${userId}/collections`)
}

const uploads = {
  upload: (file: File) => {
    const formData = new FormData();

    formData.append('file', file);

    return requests.post<IUploadResult>('/uploads', formData);
    
  }
}


const api = {
  products,
  account,
  collections,
  tags,
  users,
  uploads
}

export default api;